import * as React from 'react';
import Layout from '../components/Layout';
import { Box, Button, Link, Typography, Stack } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';
import Header from '../components/Header';
import { graphql } from 'gatsby';

type IndexProps = {
  data: GatsbyTypes.IndexNoticeQuery;
}

export default function Home({ data }: IndexProps): JSX.Element {
  const noticeText = data.allStrapiCategories.nodes.length === 1 ? data.allStrapiCategories.nodes[0].description.trim() : null;
  const notice = noticeText ?
    <Typography variant="body1" mb={[4, null, 6]}>
      <Box component="span" fontWeight="fontWeightMedium">Customer notice: </Box>
      { noticeText }
    </Typography>
    :
    null;

  return (
    <Layout title="Oclee &amp; Son: Family Jewellers in Folkestone, Kent">
      <Stack minHeight="100vh">
        <Header page="home" />
        <Box display="flex" flex={1} flexDirection="row">
          <Stack direction="row" flex={1} justifyContent="center">
            <Stack
              direction={['column-reverse', null, 'row']}
              alignSelf="center"
              maxWidth="1078px"
              px={2}
            >
              <Stack gap={3}>
                <Box mt={[4, null, 0]} alignSelf="center">
                  <Box component="img"
                    src="/images/ocleeandson.png"
                    alt="A line drawing of the Oclee &amp; Son shopfront"
                    width={['100%', '412px']}
                  />
                </Box>
                <Stack direction="row" justifyContent={['center', null, 'normal']} mb={[4, null, 2]}>
                  <Link href="https://www.facebook.com/ocleeandson/">
                    <Facebook fontSize="large" />
                  </Link>
                  <Link href="https://www.instagram.com/oclee_and_son/">
                    <Instagram fontSize="large" />
                  </Link>
                </Stack>
              </Stack>
              <Box mt={[0, null, 6]} ml={[0, null, 12]} mb={[4, null, 0]}>
                <Typography
                  variant="h1"
                  fontSize="4rem"
                  fontWeight={700}
                  lineHeight="3.6rem"
                  mb={[2, null, 4]}
                >
                  Folkestone&apos;s{' '}
                  <Box component="br" display={['none', null, 'inline']} />
                  Jewellers
                </Typography>
                <Typography variant="body1" mb={[2, null, notice ? 3 : 7]}>
                  Established 1878, Oclee &amp; Son is one of the oldest
                  remaining family-run jewellers in Folkestone. We pride
                  ourselves on providing the highest quality service and giving
                  you that personal touch, so come and say hello in store or give
                  us a call to see what we can do for you.
                </Typography>
                { notice }
                <Stack direction="row" justifyContent={["center", "center", "left"]}>
                  <Box minWidth="16rem">
                    <Stack direction="column">
                      <Button href="/catalogue" variant="contained">
                        Browse catalogue
                      </Button>
                      <Link
                        href="/contact"
                        underline="always"
                        fontSize="0.9rem"
                        textAlign={["center", null, "left"]}
                        mt={2}
                        mb={4}
                      >
                        Get in touch
                      </Link>
                      <Box component="img"
                        src="/images/naj-member.png"
                        alt="We are a member of The National Association of Jewellers"
                        width={['266px']}
                        mb={4}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Layout>
  );
}

export const query = graphql`
query IndexNotice {
  allStrapiCategories(
    filter: {
      name: {eq: "Oclee & Son Ltd"}
    }
  ) {
    nodes {
      description
    }
  }
}
`;
